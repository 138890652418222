import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { IconHomeKey, IconFinance, IconFinanceCoaching } from '../../../components';
import classNames from 'classnames';
import css from './Info.module.css';

export const InfoComponent = props => {
  const { intl, horizontal } = props;

  return (
    <div className={classNames(css.root, { [css.horizontal]: horizontal })}>
      <div className={css.content}>
        <div className={css.header}>
          <h3>
            <FormattedMessage id="Info.heading" />
          </h3>
          <p>
            <FormattedMessage id="Info.subHeading" />
          </p>
        </div>
        <div className={css.benefitsWrapper}>
          <div className={css.benefit}>
            <div className={css.icon}>
              <IconHomeKey />
            </div>
            <div className={css.benefits}>
              <h3 className={css.benefitsHeading}>
                <FormattedMessage id="Info.housingDiscountsHeading" />
              </h3>
              {horizontal ? (
                <ul className={css.benefitsList}>
                  <li>
                    <FormattedMessage id="Info.housingDiscountsBenefit1" />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Info.housingDiscountsBenefit2"
                      values={{
                        boldText: (
                          <b>
                            {intl.formatMessage({ id: 'Info.housingDiscountsBenefit2BoldText' })}
                          </b>
                        ),
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage id="Info.housingDiscountsBenefit3" />
                  </li>
                  <li>
                    <FormattedMessage id="Info.housingDiscountsBenefit4" />
                  </li>
                </ul>
              ) : (
                <ul className={css.benefitsList}>
                  <li>
                    <FormattedMessage
                      id="Info.signup.housingDiscountsBenefit1"
                      values={{
                        boldText: (
                          <b>
                            {intl.formatMessage({ id: 'Info.signup.housingDiscountsBenefit1BoldText' })}
                          </b>
                        ),
                      }}
                    />
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className={css.benefit}>
            <div className={css.icon}>
              <IconFinance />
            </div>
            <div className={css.benefits}>
              <h3 className={css.benefitsHeading}>
                <FormattedMessage id="Info.financialServicesHeading" />
              </h3>

              {horizontal ? (
                <ul className={css.benefitsList}>
                  <li>
                    <div>
                      <div>
                        <FormattedMessage id="Info.financialServicesBenefit1" />
                      </div>
                      <div className={css.details}>
                        <FormattedMessage id="Info.financialServicesBenefit1Details" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div>
                        <FormattedMessage id="Info.financialServicesBenefit2" />
                      </div>
                      <div className={css.details}>
                        <FormattedMessage id="Info.financialServicesBenefit2Details" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div>
                        <FormattedMessage id="Info.financialServicesBenefit3" />
                      </div>
                      <div className={css.details}>
                        <FormattedMessage id="Info.financialServicesBenefit3Details" />
                      </div>
                    </div>
                  </li>
                </ul>
              ) : (
                <ul className={css.benefitsList}>
                  <li>
                    <div>
                      <div>
                        <FormattedMessage id="Info.signup.financialServicesBenefit1" />
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className={css.benefit}>
            <div className={css.icon}>
              <IconFinanceCoaching />
            </div>
            <div className={css.benefits}>
              <h3 className={css.benefitsHeading}>
                <FormattedMessage id="Info.financialCoachingHeading" />
              </h3>
              {horizontal ? (
                <ul className={css.benefitsList}>
                  <li>
                    <FormattedMessage
                      id="Info.financialCoachingBenefit1"
                      values={{
                        freeFinancialCoaching: (
                          <b>{intl.formatMessage({ id: 'Info.freeFinancialCoaching' })}</b>
                        ),
                      }}
                    />
                  </li>
                </ul>
              ) : (
                <ul className={css.benefitsList}>
                  <li>
                    <FormattedMessage
                      id="Info.signup.financialCoachingBenefit1"
                    />
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className={css.footer}>
          <p>
          {horizontal ? (
            <FormattedMessage id="Info.note" /> 
          ) : (
            <FormattedMessage id="Info.signup.note" /> 
          )}
          </p>
        </div>
      </div>
    </div>
  );
};

InfoComponent.defaultProps = {};

InfoComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

export default InfoComponent;
